import { createSelector } from '@ngrx/store';
import { adapter, SecurityCheckState } from '../reducer/security-check.reducer';
import { selectSecurityCheckState } from '../index';
import { SecurityCheckFilters, SecurityCheckFiltersForApi } from '../../interfaces/security-check-filters.interface';
import { selectRouteParam } from '@core/store/selectors/router.selectors';

// entity get the selectors (ReceivingOrders)
const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();
// select the array of order ids
export const selectAllScanInOrdersIds = selectIds;
// select the dictionary of order entities
export const selectAllScanInOrdersEntities = selectEntities;
// select the array of orders
export const selectAllScanInOrders = selectAll;
// select the total order count
export const selectAllScanInOrdersTotal = selectTotal;

export const selectDrsOrdersIds = createSelector(selectSecurityCheckState, selectAllScanInOrdersIds);
export const selectDrsOrdersEntities = createSelector(selectSecurityCheckState, selectAllScanInOrdersEntities);
export const selectDrsOrders = createSelector(selectSecurityCheckState, selectAllScanInOrders);
export const selectDrsOrdersTotal = createSelector(selectSecurityCheckState, selectAllScanInOrdersTotal);

export const selectRunsheets = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.runsheets
);
export const selectTotalCount = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.totalCount
);
export const selectFilters = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.filters
);
export const selectLoading = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.loading
);
export const selectError = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.error
);
export const selectLastScan = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.lastScan
);

export const selectAppliedFilters = createSelector(selectFilters, (filters: SecurityCheckFilters) => {
  if (filters) {
    return Object.keys(filters)
      .filter(
        (k) =>
          filters[k as keyof SecurityCheckFilters] !== null &&
          filters[k as keyof SecurityCheckFilters] !== undefined &&
          k !== 'sortBy' &&
          k !== 'offset' &&
          k !== 'limit'
      )
      .reduce((a, k) => ({ ...a, [k]: filters[k as keyof SecurityCheckFilters] }), {});
  }
  return null;
});

export const selectFiltersForApi = createSelector(
  selectFilters,
  (filters: SecurityCheckFilters): SecurityCheckFiltersForApi => {
    const _filters: any = { ...filters };
    if (filters.driver && filters.driver.id) {
      _filters.driverId = filters.driver.id;
      delete _filters.driver;
    }
    // remove null and undefined values
    Object.keys(_filters).forEach((key) => _filters[key] == null && delete _filters[key]);
    return _filters;
  }
);

export const selectSessionScanningId = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.scanningSession && state.scanningSession.objectId
);
export const selectLatestScanDate = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.scanningSession && state.scanningSession.latestScanDate
);
export const selectAuthorName = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.scanningSession && state.scanningSession.authorName
);
export const selectAuthorId = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.scanningSession && state.scanningSession.authorId
);

export const selectOrderPackages = createSelector(
  selectDrsOrdersEntities,
  selectRouteParam('mp'),
  (entities, orderId) => {
    if (entities && orderId) {
      return entities[orderId]?.packages;
    }
    return null;
  }
);

export const selectAllOrdersScanned = createSelector(
  selectDrsOrders,
  (orders) => orders && !!orders.length && orders.every((o) => o.scanningStatus === 'scanned')
);
export const selectSuccessMessage = createSelector(
  selectSecurityCheckState,
  (state: SecurityCheckState) => state && state.successMesage
);
